<template>
	<el-dialog :visible.sync="show" width="40%" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false"
	 @close="close">
		<div v-if="!userShowList">
			<div slot="title" class="header-title">
				<el-row :gutter="5">
					<el-col :span="24">
						<span class="title-name" style="font-size: 20px;">{{ info.title }}</span>
					</el-col>
				</el-row>
			</div>
			<div style="width: 100%;height: 20px;"></div>
			<el-row :gutter="0">
				<el-col :span="24">
					<el-form ref="ruleForm" :model="form" label-width="110px" class="allForm">
						<el-form-item label="标签名称" :required="true">
							<el-input v-model="form.labelName" class="formInput" maxlength="25" />
						</el-form-item>
						<el-form-item label="所属企业" :required="true">
							<el-select v-model="form.companyId" class="input searchInput" filterable clearable>
								<el-option v-for="(v, i) in company" :key="i" :value="v.id" :label="v.companyName" />
							</el-select>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<div slot="footer" class="dialog-footer" style="text-align: center;">
				<el-button type="primary" @click="commit">提交</el-button>
				<el-button type="info" @click="close">取消</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import {
		company,
	} from "@/api";
	export default {
		name: 'Operate',
		props: {
			// 打开dialog的
			dialog: {
				type: Boolean, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: false // 这样可以指定默认的值
			},
			info: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			},
			row: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			}
		},
		data() {
			return {
				show: this.dialog,
				form: {
					companyId: this.$admin ? null : this.$companyId,
					labelName: '',
				},
				company: [],
			}
		},
		created() {
			this.getCompany()
		},
		mounted() {
			this.upd()
		},
		methods: {
			getCompany() {
				this.$axiosReq(
					company,
					null, {
						pageSize: 10000,
					},
					"get"
				).then((res) => {
					this.company = res.data.content;
				});
			},
			upd() {
				if (this.info.addOr === 'upd') {
					this.$axiosReq(
						'/oauth/server/escort/web/label/' + this.row.id,
						null, null,
						"get"
					).then((res) => {
						this.form = res.data;
					});
				}
			},
			// 提交添加
			commit() {
				if (!this.form.labelName) {
					this.$message.error("请输入标签名称");
					return false;
				}
				if (!this.form.companyId) {
					this.$message.error("请选择公司");
					return false;
				}
				if (this.info.addOr === 'add') {
					this.$axiosReq('/oauth/server/escort/web/label', this.form, null, 'post').then(res => {
						this.close()
						this.$emit('get-list')
						this.$message.success(res.msg)
					})
				} else {
					this.$axiosReq('/oauth/server/escort/web/label/' + this.row.id, this.form, null, 'put').then(res => {
						this.close()
						this.$emit('get-list')
						this.$message.success(res.msg)
					})
				}
			},
			// 关闭模态框
			close() {
				this.show = false
				this.$emit('refresh')
			},
		}
	}
</script>

<style scoped>
	.input-with-select {
		width: 280px;
	}

	.pb-20 {
		padding-bottom: 20px;
	}
</style>
