<template>
	<div class="dashboard-container">
		<Operate v-if="dialog.refresh" :dialog.sync="dialog.show" :row="dialog.row" :info="dialog.info" @get-list="getList"
		 @refresh="refreshDialog" />
		<el-card>
			<el-row type="flex" justify="space-between" :gutter="20">
				<div class="pl-1 pr-1">
					<span class="text-primary text-pad-right">企业名称</span>
					<el-select v-model="companyId" clearable filterable size="mini" class="input searchInput" @change="changeCondition">
						<el-option v-for="(item,index) in company" :key="index" :value="item.id" :label="item.companyName" />
					</el-select>
				</div>
			</el-row>
		</el-card>
		<el-card style="margin-top: 20px;">
			<el-row>
				<vxe-toolbar>
					<template v-slot:buttons>
						<el-row type="flex" justify="between">
							<el-col>
								<el-button type="primary" size="mini" @click="openDialog({title:'添加标签',addOr:'add'})">添加标签</el-button>
							</el-col>
							<el-col :span="1">
								<vxe-button @click="getList">刷新</vxe-button>
							</el-col>
						</el-row>
					</template>
				</vxe-toolbar>
				<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"
				 align="center" class="vxe-table-element" height="630" row-id="notice_id" :sort-config="{remote: true}"
				 :filter-config="{remote: true}" :checkbox-config="{reserve: true}" :data="table.list" :loading="loading">
					<vxe-table-column type="checkbox" width="60" fixed="left" />
					<vxe-table-column type="seq" width="60" />
					<vxe-table-column field="companyName" title="公司名称" show-overflow="title" />
					<vxe-table-column field="labelName" title="标签名称" show-overflow="title" />
					<vxe-table-column field="" title="操作">
						<template v-slot="{ row, rowIndex }">
							<el-dropdown size="small" type="primary">
								<el-button size="small" type="primary">
									操作<i class="el-icon-arrow-down el-icon--right" />
								</el-button>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item icon="el-icon-edit" @click.native="openDialog({title:'修改标签',addOr:'upd'},row)">修改</el-dropdown-item>
									<el-dropdown-item icon="el-icon-delete" @click.native="remove({del:'single'},row)">删除</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</vxe-table-column>
				</vxe-table>
				<div style="height: 20px" />
				<el-pagination background :current-page="table.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]"
				 :page-size="table.pageSize" layout="total, ->, sizes, prev, pager, next, jumper" :total="table.total" @size-change="handleSizeChange"
				 @current-change="handleCurrentChange" />
			</el-row>
		</el-card>
	</div>
</template>

<script>
	import {
		rowStyle,
		headerStyle
	} from '@/utils/tableStyleJs'
	import {
		company
	} from '@/api'
	import Operate from './Operate'
	export default {
		name: 'Index',
		components: {
			Operate
		},
		data() {
			return {
				loading: false,
				company: [],
				companyId: '',
				table: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 10,
					sortName: '',
					sortBy: ''
				},
				dialog: {
					row: Object,
					info: {},
					refresh: false, // 每次刷新DOM
					show: false
				},
			}
		},
		created() {
			this.getCompany();
		},
		methods: {
			rowStyle,
			headerStyle,
			changCondition() {
			},
			changeCondition() {
				this.table.currentPage = 1
				this.getList()
				// this.$refs.table.clearCheckboxRow()
			},
			getCompany() {
				this.$axiosReq(company, null, {
					currentPage: 1,
					pageSize: 1000,
				}, 'get').then(res => {
					if (res.data.content && res.data.content.length > 0) {
						this.company = res.data.content;
						this.companyId = res.data.content[0].id;
						this.getList();
					}
				})
			},
			getList() {
				this.loading = true
				this.$axiosReq('/oauth/server/escort/web/label', null, {
					companyId: this.companyId,
					currentPage: this.table.currentPage,
					pageSize: this.table.pageSize,
				}, 'get').then(res => {
					this.table.list = res.data.content
					this.table.total = Number(Number(res.data.totalElements))
				}).finally(e => {
					this.loading = false
				})
			},
			handleSizeChange(pageSize) {
				this.table.pageSize = pageSize
				this.table.currentPage = 1
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.table.currentPage = currentPage
				this.getList()
			},
			openDialog(info, row) {
			  this.dialog.refresh = true
			  this.dialog.show = true
			  this.dialog.info = info
			  this.dialog.row = row
			},
			refreshDialog() {
				this.dialog.refresh = false
			},
			remove(info, row) {
				this.$confirm('确认删除吗！！！', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axiosReq('/oauth/server/escort/web/label/' + row.id, null, null, 'delete').then(res => {
						this.$message.success(res.msg)
						this.getList()
					})
				}).catch(() => {
					this.$message.info('取消了删除！！！')
				})
			},
		}
	}
</script>

<style scoped>
.rowBox {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
</style>
